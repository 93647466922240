import { AUTH_USER_STORE_NAME } from "~~/composables/states";
import { LoginResponse } from "~~/composables/useLogin";

/**
 * Require Users Authentication
 */
export default defineNuxtRouteMiddleware((to, from) => {
  if (isAppInGuestMode()) {
    // Then no auth is required. Allow direct access.
    return true;
  }

  let user: LoginResponse | null = null;

  const isClient = process.client;
  const isServer = process.server;

  if (isServer) {
    user = useCookieParser().value[AUTH_USER_STORE_NAME] ?? null;
  } else if (isClient) {
    user = useAuthUser().value;
  }

  // Not Logged In at all redirect to login
  if (!user || !user?.access_token) {
    useToast().error("Login to Continue", {
      position: "bottom",
    });

    return navigateTo("/?redirectTo=" + to.path);
  }
});
